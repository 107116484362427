
import { computed, defineComponent, ref } from "vue";
import { hideModal } from "@/core/helpers/dom";

import { useStore } from "vuex";
// import $ from 'jquery';
import { useRoute, useRouter } from "vue-router";
import modalTable from '@/components/partials/Invoices/modalTable.vue';
import InvoiceService from "@/core/services/car/InvoiceService";


export default defineComponent({
  name: "add-customer-modal",
  components: {
    modalTable
  },
  props: {
    invoiceObj: Array,
    
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const viewInvoiceDocumentModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);

    const headers = ref([
      {
        label: 'Preview',
        prop: 'file_path',
        width: '300',
        align: 'center'
      },
      {
        label: 'Document Type',
        prop: 'title',
        width: '250',
        align: 'center'
      },
      {
        label: 'File Name',
        prop: 'filename',
        width: '250',
        align: 'center'
      },
      {
        label: 'Created at',
        prop: 'created_at',
        width: '180',
        align: 'center'
      },
    ])
    
    // const formData = ref({});
    const store = useStore();

    const route = useRoute();
    hideModal(viewInvoiceDocumentModalRef.value);

    const showInvoiceDocumentDom = computed(() => {
      return store.getters.getInvoiceDocumentDom;
    });
    const isOpen = computed(() => {
      return store.getters.getOpenDocuments
    })

    const closeModal = () => {
      InvoiceService.closeModal();
    }

    

    return {
      closeModal,
      isOpen,
      headers,
      loading,
      viewInvoiceDocumentModalRef,
      showInvoiceDocumentDom,
      
    };
  },
});
