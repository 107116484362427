<template>
  <el-table
    :data="dataTable"
    :show-summary="isSummary"
    :sum-text="sumText"
    stripe
    style="width: 100%"
    class="table-icons-custom"
  >
    <el-table-column v-if="isIndex" width="60">
      <template #header>
        {{ indexName }}
      </template>
      <template #default="scope">
        {{ scope.$index + 1 }}
      </template>
    </el-table-column>
    <el-table-column
      v-for="(column, index) in columns"
      :key="index"
      :prop="column.prop"
      :label="column.label"
      :align="column.align"
      :class-name="className"
    >
      <template v-if="['file_path', 'title'].includes(column.prop)" #default="props">
        <div v-if="column.prop === 'file_path'">
          <span v-if="props.row.file_extension.toLowerCase() === 'pdf'">
            <img src="media/svg/files/pdf.svg" alt="svg" />
          </span>
          <span v-else>
            <img
              :src="props.row.file_path"
              height="50"
              width="50"
              @click="openSesame(props.row)"
            />
          </span>
        </div>
        <div v-if="column.prop === 'title'">
          <span :class="{ 'text-success': props.row.is_temp === 0 }"> {{ props.row.title ? props.row.title : (props.row.document_type ? props.row.document_type.type : 'Not Selected') }} </span>
        </div>
      </template>
     
    </el-table-column>
    <el-table-column v-if="isACtion">
      <template #header> Actions </template>
      <template #default="props">
        <div class="d-flex align-items-center">
          <el-tooltip
            class="box-item"
            effect="light"
            content="View"
            placement="bottom"
            :show-after="500"
          >
            <a
              @click="addLog.addDocumentLog(props.row.id, 'view', 'invoice')"
              :href="props.row.file_path"
              target="_blank"
              class="btn-outline-brand icon-bg"
            >
              <i class="fa fa-eye text-info"></i>
            </a>
          </el-tooltip>
          <el-tooltip
            class="box-item"
            effect="light"
            content="Document Log"
            placement="bottom"
            :show-after="500"
            style="margin-left: 7px"
          >
            <DocumentAction
              :refId="props.row.id"
              moduleType="invoice"
              :documentLogObj="getDocumentLog"
            />
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
  </el-table>
  <!-- v-if="documentLogObj.length > 0"-->
  <DocumentLogsModal
    :dataTable="documentLog"
    :isAction="false"
    indexName="Sr.#"
  />

  <el-dialog
    v-model="openMouseEnter"
    width="50%"
    center
    destroy-on-close
    custom-class="no-padding"
  >
    <el-image :src="selected.file_path" @click="openMouseEnter = false" />
  </el-dialog>

</template>

<script>
import { computed, defineComponent, ref } from "vue";
import { addLog } from "@/store/stateless/store";
import { useStore } from "vuex";

import DocumentAction from "@/components/partials/logs/DocumentAction.vue";
import DocumentLogsModal from "@/components/partials/logs/DocumentLogsModal.vue";

export default defineComponent({
  props: {
    dataTable: {
      required: true,
      default: []
    },
    columns: {
      required: true,
      default: [
        {
          label: "",
          prop: "",
          width: ""
        }
      ]
    },
    isACtion: {
      required: true,
      default: false
    },
    indexName: {
      required: false,
      default: "Sr.#"
    },
    isIndex: {
      type: Boolean,
      required: false,
      default: true
    },
    className: {
      type: String,
      required: false
    },
    isSummary: {
      type: Boolean,
      required: false,
      default: false
    },
    sumText: {
      type: String,
      required: false,
      default: "Total"
    }
  },
  components: {
    DocumentAction,
    DocumentLogsModal
  },
  setup() {
    const store = useStore();
    const selected = ref();
    const openMouseEnter = ref(false);

    const openSesame = (e) => {
      selected.value = e;
      openMouseEnter.value = !openMouseEnter.value;
    };

    const documentLogObj = ref < Array > [];
    const getDocumentLog = (documentLogObj) => {
      documentLogObj.value = documentLogObj;
    };

    const documentLog = computed(() => {
      return store.getters.getDocumentLogs;
    });

    return {
      selected,
      openMouseEnter,
      documentLog,
      addLog,
      openSesame,
      getDocumentLog,
      documentLogObj
    };
  }
});
</script>
