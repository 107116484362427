<template>
   <el-dialog
    v-model="isOpen"
    title="Document Logs"
    width="50%"
    @close="closeDocumentLogModal"
    @close-auto-focus="closeDocumentLogModal"
  >
  <template #default>
        
        <div class="container p-0">
           <el-table
                :data="dataTable"
                :show-summary="isSummary"
                :sum-text="sumText"
                stripe
                style="width: 100%"
                class="table-icons-custom"
            >
                <el-table-column width="100" v-if="isIndex">
                  <template #header>
                      {{ indexName }}
                  </template>
                  <template #default="scope">
                      {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                v-for="(column, index) in columns"
                :key="index"
                :prop="column.prop"
                :label="column.label"
                :align="column.align"
                :class-name="className"
                >
                <template v-if="column.prop == 'file_path'" #default="props">
                    <div v-if="props.row.file_extension.toLowerCase() === 'jpg'">
                    <el-image
                        style="width: 50px; height: 50px"
                        :src="props.row.file_path"
                        fit="contain"
                        @click="openSesame(props.row)"
                    />
                    <!-- <img :src="props.row.file_path" :alt="props.row.title" width="250" height="250" contain /> -->
                    </div>
                    <div v-else>
                    <img src="media/svg/files/pdf.svg" alt="svg" />
                    </div>
                </template>
                </el-table-column>

           </el-table>
        </div>
    </template>
  </el-dialog>  
 
</template>

<script>
import { computed, defineComponent,ref } from "vue";
import { addLog } from '@/store/stateless/store'
import MasterService from "@/core/services/car/MasterService";
import { useStore } from "vuex";

export default defineComponent({
  props: {
    dataTable: {
      required: true,
      default: [],
    },
    indexName: {
      required: false,
      default: "Sr.#",
    },
    isIndex: {
      type: Boolean,
      required: false,
      default: true,
    },
    className: {
      type: String,
      required: false,
    },
    isSummary: {
      type: Boolean,
      required: false,
      default: false,
    },
    sumText: {
      type: String,
      required: false,
      default: "Total",
    },
  },
  setup() {
    const store = useStore();
    const columns = ref([
      {
        label: 'Action',
        prop: 'action',
        width: '150',
        align: 'center'
      },
      {
        label: 'By',
        prop: 'action_by.name',
        width: '200',
        align: 'center'
      },
      {
        label: 'At',
        prop: 'created_at',
        width: '150',
        align: 'center'
      },
    ])
    const openSesame = (e) => {
      console.log(e);
    };

    const isOpen = computed(() => {
      return store.getters.getOpenDocumentLogs
    })

    const closeDocumentLogModal = () => {
      MasterService.closeModal();
    }

    return {
      addLog,
      openSesame,
      columns,
      isOpen,
      closeDocumentLogModal,
    };
  },
});
</script>
