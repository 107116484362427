
import { defineComponent, ref, onMounted, computed } from "vue";

import { useStore } from "vuex";
import { listLog } from "@/store/stateless/store";

export default defineComponent({
  name: "document-action",
  components: {},
  props: {
    refId: Number,
    moduleType: String,
  },

  setup() {
    const store = useStore();
    const userPermissions = computed(() => {
      return store.getters.getUserPermissions;
    });

    /**
     * return data
     *
     */
    return {
      userPermissions,
      store,
      listLog,
    };
  },
});
